<template>
    <div 
        class="" 
        :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}"
    >
        <div class="ml-1">
            <v-row class="pa-0 pt-2 bread-crumb">
                <v-col cols="5" class="ma-0 pa-0">
                    <hb-breadcrumb @click="goBreadcrumbRoute">{{ breadcrumbText }}</hb-breadcrumb>
                </v-col>
                <v-spacer/>
            </v-row>
            <hb-header :divider="false">
                <hb-page-header
                    :title="`${getHeaderNameData}`"
                    class="mb-2"
                />
                <span 
                    class="hb-font-header-3 mb-2"
                    style="color: #637381"
                >
                    {{ propertyName }}
                </span>
            </hb-header>
        </div>
        <div 
            :style="{ height: reportHeight + 'px' }"
            v-resize="setHeight"
        >
            <hb-report
                :key="report_key"
                report_type="space_group_spaces"
                :actions_panel="[ 'master_filter', 'export', 'columns']"
                right_click
                left_slot
                :show_default_report_option="true"
                row_click="unit_view"
                :default_column_filters="column_filters"
                :default_search_filters="search_filters"
            >
            <!-- show_search and bulk_edit currently set to hidden -->
            <!-- while using 'default_search_filters' be sure to remove the filter while destroying or changing to another report(only required while using same report name on 2 differnt reports) -->

                <template v-if="type == 'rate'" v-slot:left>
                    <div class="d-flex align-center mb-n5">
                        <span class="hb-font-header-3-medium mb-4"> {{capitalizeFirstLetter(type)}} Plan: {{ spaceData.spacegroup_rate_plan_name }}</span>
                        <hb-icon 
                            class="ml-1 mb-3"
                        >  
                            {{ icon }}
                        </hb-icon>
                    </div>
                </template>
            </hb-report>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import HbReport from '../../assets/HummingbirdReportViewer.vue';
import { capitalizeFirstLetter } from "../../../utils/common";
export default {
    name: "RateManagementSpaces",

    components: {
        HbReport
    },

    data() {
        return {
            report_key: 0,
            breadcrumbText: `Back to ${capitalizeFirstLetter(this.type)} Management by Property`,
            emptySpaceData: {},
            reportHeight: 0,
            returnFromContact: false,
            column_filters: [
            ]
        }
    },

    props: {
        type: {
            type: String,
            default: 'rate'
        }
    },
    mounted() {
        this.$store.commit("reportStore/setDynamicRunParam", {
            propertyIDArray: this.property ? [this.property] : undefined,
        });
    },

    computed: {
        ...mapGetters({
            spaceData: "revManStore/getSpaceData",
            properties: "propertiesStore/filtered",
            property: "revManStore/getSelectedProperty",
        }),
        search_filters() {
            return [
                    {
                        id : 'space_group_id',
                        value: this.spaceData.spacegroup_id
                    } 
                ]
            } ,
            
        propertyName() {
            let property = (this.properties ?? []).find(property => property.id === this.property)
            this.returnFromContact = false
            if(!property) {
                property = (this.properties ?? []).find(property => property.id === this.spaceData?.propertyId)
                this.returnFromContact = true
                console.log("return from contact -- >", this.returnFromContact)
                this.setProperty(this.spaceData?.propertyId)
            }
            return property?.name ?? ''
        },
        icon() {
            return {
                true: 'mdi-table-actions-enable',
                false: 'mdi-table-actions-disable'
            }[!!this.spaceData.spacegroup_rate_management_active]
        },
        getHeaderNameData() {
            let unitTypeTitle = this.getUnitTypeTitle({unit_type_id:this.spaceData?.spacegroup_spacetype_id})
            return this.parseColumnsOrEscape([
                unitTypeTitle,
                this.spaceData?.spacegroup_amenities,
                this.spaceData?.spacegroup_size
            ], ': ')
        }
    },

    methods: {
        ...mapMutations({
            setSpaceData: 'revManStore/SET_SPACE_DATA',
            setProperty: "revManStore/setProperty",
        }),
        parseColumnsOrEscape(items, delimitter) {
            let result = items.reduce((acc, value, currIndex, arr) => {
                if(arr[currIndex - 1] && (value || arr[currIndex + 1] )) acc.push(delimitter)
                if(value) acc.push(value)
                return acc
            }, [])
            return result.join('')
        },
        capitalizedString(string) {
            if(string){
                string = string.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
            }
            return string;
        },
        goBreadcrumbRoute() {
            this.setSpaceData(this.emptySpaceData)
            this.$store.commit("reportStore/setDynamicRunParam", {
                propertyIDArray: this.property ? [this.property] : undefined,
            });
            this.$emit('setPreviousData', {
                propertyId: this.spaceData?.propertyId,
                returnStatus: this.returnFromContact,
                hash: this.spaceData?.spacegroup_id_hash
            })
        },
        setHeight() {
            let header = 0;
            let stepper = 100;
            let footer = 88;
            let heading = 25;
            this.reportHeight =
                window.innerHeight -
                header -
                stepper -
                heading -
                footer;
        },
        // viewUnit(data){
        //     console.log("!@#$%^&*()_+!@#$%^&*()_!@#$%^&*()@#$%^&*(#$%^&*#$%^&*#$%^&*)")
        //     this.$store.dispatch('navigationStore/openSlideOut', {
        //         component: 'unit',
        //         props: {
        //             unit_id: data.unit_id
        //         }
        //     });
        // }
        capitalizeFirstLetter(value) {
            return capitalizeFirstLetter(value)
        }
    },

    destroyed() {
        if (!this.$route.fullPath.includes('/contacts')) this.setSpaceData(this.emptySpaceData)
        this.setProperty(null)
    },
}
</script>
<style lang="scss" scoped>
.bread-crumb {
    margin-left: -5px;
}
</style>